import React from 'react';
import { Skills } from "../../ProjectData";

export default function About() {
  return (

    <section id="about" className="sm:px-24 px-5 flex-grow  max-w-screen-xl sm:text-xl md:text-2xl text-base" >
          
          <h1 className="font-medium flex justify-evenly text-lucy-purple  subpixel-antialiased sm:text-5xl md:text-7xl text-3xl text-center mb-4 ">
            Welcome, <br /> I'm Lucy.
          </h1>

            <p className="py-3">  I'm a customer-centric, highly self-motivated person who thrives on exceeding agreed business targets.</p> 
            <p className="py-3">  Based in Sydney, Australia.</p>
            <p className="py-3">  Excellent understanding of the drivers for profit. </p>
            <p className="py-3">  Skilled in customer relations and resolving customer complaints to retain customer loyalty and company revenue.</p>  
            <p className="py-3">  My other skills: </p>  

              <ul className="tags-list flex flex-wrap justify-center">
                {Skills.map( ( skill, index ) => ( <li key={index} className="tag">{skill}</li> ))}
              </ul>
            
            <div className="py-3">enjoys…</div>
      
            <ul className="ml-16 pb-5 list-square">
              <li>Art galleries</li>
              <li>Drawing</li>
              <li>Family</li>
            </ul>

    </section>

  );
}
