import React from 'react';

export default function Footer() {

  return ( 
      <div className=" p-4 flex justify-evenly max-w-screen-xl">

        <section>
          <address className="social-network" itemScope itemType="http://schema.org/LocalBusiness"> 
              <a className = "contact-links" href="tel:+61434919399">
                <div className="inline-block text-4xl sm:px-5 px-1 sm:text-4xl md:text-6xl text-2xl contact-icons icon-mobile-phone" style={{color: "purple"}}></div>
              </a>

            <a  className = "contact-links" href="mailto:lucy@watsonised.com?subject=Sending%20you%20this%20email%20from%20your%20website&body=Hi%20Lucy,%20I'm%20emailing%20you%20from%20your%20website." 
                aria-label="send me an email by selecting this icon">
              <div className="inline-block text-4xl sm:px-5 px-1 m:text-4xl md:text-6xl text-2xl contact-icons icon-SVG_Envelope" style={{color: "purple"}}></div>
            </a>
                
          </address>
        </section>

      </div>
  );
  
}