
export const AppsData = [
    // {
    //   title: "Flickering",
    //   subtitle: "MERN GraphJS Apollo",
    //   description:"A tracker for your emotions / mood.",
    //   image: "./assets/projects/flickering_notesPage.jpg",
    //   link: "https://flickering.herokuapp.com",
    //   github: "https://github.com/Mark33Mark/flickering",
    // },
  ];
  
  // Here for a future update when I actually get some references for work I've done.
  export const Testimonials = [
    {
      quote:"",
      image: "",
      name: "",
      company: "",
    },
    {
      quote:"",
      image: "",
      name: "",
      company: "",
    },
  ];
  
  export const Skills = [
    "Apple device expert",
    "Communications Contracts",
    "Customer Complaints",
    "Workplace Safety",
    "Sales Budgetting",
  
  ];
  